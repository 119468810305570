@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital@0;1&family=Sarabun:ital,wght@0,400;0,500;1,400;1,500&display=swap');

body{
  font-family: "Open Sans", "Sarabun", sans-serif;
}

* {
  overflow: hidden;
  background-color: wheat;
}

.main-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100vh;
}

.center-text {
  font-size: 55px;
  margin-bottom: 110px;
  font-weight: 700;
  color: #fff;
}

.button-container {
  display: flex;
}

.button-container .btn-yes, .btn-no{
  padding: 15px 25px;
  color: white;
  border: none;
  cursor: pointer;
}

.btn-yes {
  top: 51.4%;
  left: 43%;
}

.button-container .btn-yes {
  background-color: #0056b3;
}

.button-container .btn-no {
  background-color: red;
}

.button-container button:hover {
  background-color: #0056b3;
}
